import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { defer, remove } from "lodash";
import { useEffect, useState } from "react";

const listeners = new Array<((message: ToastMessage) => void)>();

type ToastMessage = ToastMessageOptions & {
	message: string
}

type ToastMessageOptions = {
	severity?: Color,
	onClose?: () => void
}

export function createWarningToast(message: string, onClose?: () => void) {
	console.warn('toast', message);
	createToast(message, {
		severity: "warning",
		onClose: onClose
	});
}

export function createInfoToast(message: string, onClose?: () => void) {
	console.log('toast', message);
	createToast(message, {
		severity: "info",
		onClose: onClose
	});
}

export function createSuccessToast(message: string, onClose?: () => void) {
	console.log('toast', message);
	createToast(message, {
		severity: "success",
		onClose: onClose
	});
}

export function createErrorToast(message: string, onClose?: () => void) {
	console.error('toast', message);
	createToast(message, {
		severity: "error",
		onClose: onClose
	});
}


export function createToast(message: string, options: ToastMessageOptions) {
	for (let listener of listeners) {
		listener({ message, ...options });
	}
}

export default function ToastContainer(props: {}) {
	const [currentToast, setCurrentToast] = useState<ToastMessage | null>(null);

	useEffect(() => {
		const listener = (value: ToastMessage) => {
			setCurrentToast(value);
		};
		listeners.push(listener);

		return () => {
			remove(listeners, l => l === listener);
		};
	}, []);

	const handleClose = () => {
		const callback = currentToast?.onClose;
		setCurrentToast(null);

		defer(() => {
			if (callback)
				callback();
		});
	}

	const message = currentToast?.message || "";
	const closeDelay = message.length * 250;

	if (!currentToast)
		return null;

	return <Snackbar autoHideDuration={closeDelay} open={!!currentToast} onClose={handleClose}>
		<Alert onClose={handleClose} severity={currentToast?.severity || "success"} style={{fontSize: "1rem"}}>
			{message}
		</Alert>
	</Snackbar>
}